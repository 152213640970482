<template>
    <div class="home-box">
      <!-- 查询区域 -->
  
      <!-- table区域-begin -->
      <div class="form-box">
        <el-form ref="form" :model="form" :rules="rules" label-width="140px" v-loading="loading">
          <div>
            <div class="el-dialog-header-box">
              <div class="dialog-title-block"></div>
              <span class="dialog-title-text">推广员佣金</span>
            </div>
            <el-divider/>
          </div>
          <el-form-item label="佣金" prop="promoter_cost">
              <el-input v-model="form.promoter_cost"  placeholder="请输入佣金">
                <template slot="append">%</template></el-input>
          </el-form-item>
          
          <div>
            <div class="el-dialog-header-box">
              <div class="dialog-title-block"></div>
              <span class="dialog-title-text">合伙人提现设置</span>
            </div>
            <el-divider/>
          </div>
          <el-form-item label="提现手续费" prop="agent_cost">
            <el-input v-model="form.agent_cost" placeholder="提现手续费 ">
              <template slot="append">%</template></el-input>
          </el-form-item>
          <el-form-item label="提现最小额度" prop="agent_balance">
              <el-input v-model="form.agent_balance" placeholder="提现最小额度"></el-input>
          </el-form-item>

          <div>
            <div class="el-dialog-header-box">
              <div class="dialog-title-block"></div>
              <span class="dialog-title-text">支付宝账号设置</span>
            </div>
            <el-divider/>
          </div>
          <el-form-item label="支付宝appid" prop="appid">
            <el-input v-model="form.appid" placeholder="支付宝appid "></el-input>
          </el-form-item>
          <el-form-item label="应用公钥" prop="cert_public_key">
              <el-upload
                class="upload-demo"
                action="#"
                ref="upload"
                multiple
                :limit="1"
                :file-list="fileList.cert_public_key"
                :on-preview="handlePreview"
                :on-remove="function(file, fileList) {return handleRemove(file, fileList,'cert_public_key')}"
                :http-request="function(file) {return handleFileUpload(file,'cert_public_key')}"
                >
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
          </el-form-item>
          <el-form-item label="支付宝公钥" prop="tixian_cert_public_key">
              <el-upload
                class="upload-demo"
                action="#"
                ref="upload"
                multiple
                :limit="1"
                :file-list="fileList.tixian_cert_public_key"
                :on-preview="handlePreview"
                :on-remove="function(file, fileList) {return handleRemove(file, fileList,'tixian_cert_public_key')}"
                :http-request="function(file) {return handleFileUpload(file,'tixian_cert_public_key')}"
                >
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
          </el-form-item>
          <el-form-item label="支付宝根证书" prop="root_key">
              <el-upload
                class="upload-demo"
                action="#"
                ref="upload"
                multiple
                :limit="1"
                :file-list="fileList.root_key"
                :on-preview="handlePreview"
                :on-remove="function(file, fileList) {return handleRemove(file, fileList,'root_key')}"
                :http-request="function(file) {return handleFileUpload(file,'root_key')}"
                >
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
          </el-form-item>
          <el-form-item label="支付宝应用秘钥" prop="pri_key">
              <!-- <el-upload
                class="upload-demo"
                action="#"
                ref="upload"
                multiple
                :limit="1"
                :file-list="fileList.pri_key"
                :on-preview="handlePreview"
                :on-remove="function(file, fileList) {return handleRemove(file, fileList,'pri_key')}"
                :http-request="function(file) {return handleFileUpload(file,'pri_key')}"
                >
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload> -->
              <el-input type="textarea" :rows="3" v-model="form.pri_key" placeholder="支付宝应用秘钥"></el-input>
          </el-form-item>
          <el-form-item label-width="50%">
              <el-button type="primary" @click="onSubmit('form')">提交</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- table区域-end -->
  
      <!-- 分页区域-begin -->
      <!-- 分页区域-end -->
    </div>
  </template>
  
  <script>
  import { mapState, mapMutations } from "vuex";
  
  export default({
    name: "agent",
    data() {
      return {
        form: {
          promoter_cost: '',
          agent_cost: '',
          agent_balance: '',

          appid: '',
          cert_public_key: [],
          tixian_cert_public_key: [],
          root_key: [],
          pri_key: '',
        },
        fileList: {
          cert_public_key: [],
          tixian_cert_public_key: [],
          root_key: [],
        },
        rules: {
          promoter_cost: [
            { required: true, message: '请输入佣金', trigger: 'blur' }
          ],
          agent_cost: [
            { required: true, message: '请输入提现手续费', trigger: 'blur' }
          ],
          agent_balance: [
            { required: true, message: '请输入提现最小额度', trigger: 'blur' }
          ],
        },
        loading: false,
      };
    },
    computed: {
      ...mapState(["user", "setting"]),
    },
    mounted() {
      let user = JSON.parse(localStorage.user);
      this.token = user.token;

      this.getSetting()
    },
    methods: {
      ...mapMutations(["setuser", "setsetting"]),
      // 处理图片移除操作
      handleRemove(file, fileList, type) {
        console.log('file, fileList',file, fileList, type);
        this.form[type] = this.form[type].filter(obj => obj.uid !== file.uid);
        // this.form[type] = ''
        // this.fileList[type] = []
      },
      // 处理图片预览操作
      handlePreview(file) {
        console.log(file);
      },
      // 处理文件、图片上传操作
      handleFileUpload(file,type) {
        console.log('file', file);
        console.log('type', type ,  this.form);
        // 使用FormData传参数和文件
        var form = new FormData();
        form.append("file", file.file);
        this.$api.upImg(form).then(res => {
            if(res.code == 'success'){
              console.log('res.data', res.data);
              this.form[type] = res.data.show_url
              // this.form[type].push({uid: file.file.uid, url:res.data.show_url})
            }else{
              this.$message.error(res.msg)
            }
            this.loading = false
          }
        ).catch((e) => {
          this.$message.error(e.message);
          this.$refs.upload.clearFiles();
        })
      },
      // 提交表单
      onSubmit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.loading = true
            let formData = new FormData()
            formData.append('pri_key', this.form.pri_key)
            this.$api.cashOutSetting({...this.form, formData}).then(
              res => {
                if(res.code == 'success'){
                  this.$message.success(res.msg)
                  this.getSetting()
                }else{
                  this.$message.error(res.msg)
                }
                this.loading = false
              }
            ).catch(err=>{
              this.$message.error(err.msg)
              this.loading = false
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      // 获取已支付订单列表
      getSetting() {
        this.loading = true
        this.fileList.cert_public_key = []
        this.fileList.tixian_cert_public_key = []
        this.fileList.root_key = []
        this.$api.getSetting({}).then(
          res => {
            if(res.code == 'success'){
              this.form = Object.assign({}, res.data);
              this.fileList.cert_public_key.push({url: res.data.cert_public_key, name: res.data.cert_public_key})
              this.fileList.tixian_cert_public_key.push({url: res.data.tixian_cert_public_key, name: res.data.tixian_cert_public_key})
              this.fileList.root_key.push({url: res.data.root_key, name: res.data.root_key})
            }else{
              this.$message.error(res.msg)
            }
            this.loading = false
          }
        ).catch(err=>{
          this.$message.error(err.msg)
          this.loading = false
        })
      },
    },
  });
  </script>
  
  <style scoped lang="less">
  @import "../assets/less/easyElement.less";
  </style>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #949494;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.el-divider--horizontal {
    display: block;
    height: 1px;
    width: 100%;
    margin: 0 0 24px ;
}
</style>
  